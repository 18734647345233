// Stolen directly from https://github.com/the-road-to-learn-react/use-combined-reducers
// because the NPM installed version has a build error which prevents us using it.
export const useCombinedReducers = (combinedReducers) => {
    // Global State
    const state = Object.keys(combinedReducers).reduce(
        (acc, key) => ({ ...acc, [key]: combinedReducers[key][0] }),
        {},
    );

    // Global Dispatch Function
    const dispatch = (action: any) => Object.keys(combinedReducers)
        .map((key: string) => combinedReducers[key][1])
        .forEach((fn: Function) => fn(action));

    return [state, dispatch];
};
