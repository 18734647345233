import getConfig from "next/config";

const getFetchError = (thing: string, system: string, url: string = "") => {
    const { publicRuntimeConfig: { appName } } = getConfig();
    return `${appName} was unable to request ${thing} on the ${system}${url ? ` ${url}` : ""}.`;
};

export {
    getFetchError
};
