import {
    ArticleAction,
    ArticleActionTypes
} from "interfaces/context/appContextInterfaces";
import {
    ApiArticleBreadcrumbLink, ApiArticleCmsEntity, ApiArticleMeta, ApiArticleSeo
} from "utils/api";
import {
    AppArticle,
    ArticleCompanySponsor,
    ArticleCompanySponsoredWidgetProps,
    ArticleContentAuthor,
    ArticleLeadMedia,
    ArticlePrimaryTopic
} from "utils/orm";

export const defaultArticleState: AppArticle = {
    articleUri: "",
    articleContentModules: [],
    blockHeadingPrefix: "",
    breadcrumbLink: {} as ApiArticleBreadcrumbLink,
    breadcrumbLinks: [],
    careerTypes: [],
    category: "",
    categorySlug: "",
    componentProps: {
        sponsorWidgetProps: {} as ArticleCompanySponsoredWidgetProps
    },
    contentAuthor: {} as ArticleContentAuthor,
    dateUpdated: "",
    excerpt: "",
    id: 0,
    isLegacy: false,
    includeInRecents: false,
    leadMedia: {} as ArticleLeadMedia,
    listicleContentType: "",
    listBuilder: [],
    postDate: "",
    postListBodyContent: [],
    primaryTopic: {} as ArticlePrimaryTopic,
    product: {} as ApiArticleCmsEntity,
    seo: {} as ApiArticleSeo,
    slug: "",
    sponsor: {} as ArticleCompanySponsor,
    // augmentedSponsor: {},
    tags: [],
    templateName: "",
    title: "",
    meta: {} as ApiArticleMeta
};

export const articleReducer = (state: AppArticle, action: ArticleAction): AppArticle => {
    switch (action.type) {
    case ArticleActionTypes.updateArticle: {
        const updatedState = {
            ...state,
            ...action.payload
        };
        return updatedState;
    }
    case ArticleActionTypes.resetArticle: {
        return defaultArticleState;
    }
    default: {
        return state;
    }
    }
};
