import {
    AppUser,
    UserAction,
    UserActionTypes
} from "interfaces/context/appContextInterfaces";

export const defaultUserState: AppUser = {
    isInitialized: false,
    logged_in: false,
    view_bookings: false,
    company: null,
    location: {
        city: "",
        region: "",
        country: "",
        dma: "",
        lat: null,
        lon: null,
        accuracy_radius: null,
    },
    cropped_name: "",
    email: "",
    favorite_posts: [],
    name: "",
    id: null
};

export const userReducer = (state: AppUser, action: UserAction) => {
    switch (action.type) {
    case UserActionTypes.updateUser: {
        const updatedState = {
            ...state,
            ...action.payload
        };
        return updatedState;
    }
    case UserActionTypes.resetUser: {
        return {
            ...defaultUserState,
            location: state?.location
        };
    }
    case UserActionTypes.resetLocation: {
        return {
            ...state,
            location: defaultUserState?.location
        };
    }
    default: {
        return state;
    }
    }
};
