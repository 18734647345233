/* eslint-disable camelcase */
import { User } from "@themuse/design-system/lib/main";

import { AppArticle } from "utils/orm";

export interface PageMetaData {
    pathName?: string;
    referrer?: string;
    search?: string;
    href?: string;
}

export interface AppUser extends User {
    isInitialized: boolean;
}

export interface AppState {
    user?: AppUser;
    article: AppArticle;
    pageMetaData: PageMetaData;
}

export enum UserActionTypes {
    updateUser = "update_user",
    resetUser = "reset_user",
    resetLocation = "reset_location"
}

export interface UserAction {
    type: UserActionTypes;
    payload?: User;
}

export enum ArticleActionTypes {
    updateArticle = "update_article",
    resetArticle = "reset_article"
}

export interface ArticleAction {
    type: ArticleActionTypes;
    payload?: AppArticle;
}

export enum PageMetaDataActionTypes {
    updatePageMetaData = "update_pageMetaData",
    resetPageMetaData = "reset_pageMetaData"
}

export interface PageMetaDataAction {
    type: PageMetaDataActionTypes;
    payload?: PageMetaData;
}

export interface AppDispatch {
    (action: ArticleAction | UserAction | PageMetaDataAction): void;
}
