// Months array for date formatting
const months: Array<string> = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
];

// Character limit for suggestion api
const suggestionApiQueryMaxlength: number = 70;

// Character limit for job search api
const jobSearchQueryMaxlength: number = 40;

const ACTIVE_FEATURE_FLAGS: string[] = [];

// image widths within the lead media.
const LEAD_MEDIA_SMALL_WIDTH = 375;
const LEAD_MEDIA_MEDIUM_WIDTH = 567;
const LEAD_MEDIA_LARGE_WIDTH = 700;
const LEAD_MEDIA_ASPECT_HEIGHT = 9;
const LEAD_MEDIA_ASPECT_WIDTH = 16;

// Screen widths
const LARGE_SCREEN_WIDTH_MIN = 1200;
const MEDIUM_SCREEN_WIDTH_MAX = 1199;
const MEDIUM_SCREEN_WIDTH_MIN = 992;
const SMALL_SCREEN_WIDTH_MAX = 991;
const SMALL_SCREEN_WIDTH_MIN = 768;
const EXTRA_SMALL_SCREEN_WIDTH_MAX = 767;

const FIXED_NAV_TABLET_HEIGHT = 64;

const careerTypeMapping = {
    Default: [
        "Software Engineering",
        "Data and Analytics",
        "Business Operations",
        "Human Resources and Recruitment",
        "Sales",
        "Project Management",
        "Account Management",
        "Healthcare"
    ],
    Tech: [
        "Software Engineering",
        "Computer and IT",
        "Data and Analytics",
        "Product Management",
        "Project Management",
        "Science and Engineering",
        "Account Management",
        "Design and UX"
    ],
    "Software Engineering": [
        "Software Engineering",
        "Computer and IT",
        "Data and Analytics",
        "Science and Engineering",
        "Product Management",
        "Project Management",
        "Management",
        "Design and UX"
    ],
    "Customer Service": [
        "Customer Service",
        "Sales",
        "Administration and Office",
        "Account Management",
        "Retail",
        "Food and Hospitality Services",
        "Business Operations",
        "Management"
    ],
    Marketing: [
        "Advertising and Marketing",
        "Sales",
        "Media, PR, and Communications",
        "Design and UX",
        "Account Management",
        "Project Management",
        "Writing and Editing",
        "Business Operations"
    ],
    Finance: [
        "Accounting and Finance",
        "Data and Analytics",
        "Business Operations",
        "Account Management",
        "Real Estate",
        "Human Resources and Recruitment",
        "Management",
        "Project Management"
    ],
    Law: [
        "Legal Services",
        "Accounting and Finance",
        "Business Operations",
        "Writing and Editing",
        "Social Services",
        "Human Resources and Recruitment",
        "Education",
        "Management"
    ],
    Education: [
        "Education",
        "Social Services",
        "Administration and Office",
        "Human Resources and Recruitment",
        "Media, PR, and Communications",
        "Writing and Editing",
        "Project Management",
        "Customer Service"
    ],
    Design: [
        "Design and UX",
        "Product Management",
        "Writing and Editing",
        "Data and Analytics",
        "Arts",
        "Advertising and Marketing",
        "Media, PR, and Communications",
        "Entertainment and Travel Services"
    ],
    Editorial: [
        "Writing and Editing",
        "Advertising and Marketing",
        "Business Operations",
        "Media, PR, and Communications",
        "Product Management",
        "Administration and Office",
        "Arts",
        "Customer Service"
    ],
    Accounting: [
        "Accounting and Finance",
        "Data and Analytics",
        "Business Operations",
        "Account Management",
        "Real Estate",
        "Administration and Office",
        "Management",
        "Legal Services"
    ],
    Nonprofit: [
        "Social Services",
        "Administration and Office",
        "Management",
        "Project Management",
        "Business Operations",
        "Legal",
        "Services",
        "Healthcare",
        "Education"
    ],
    UX: [
        "Design and UX",
        "Product Management",
        "Writing and Editing",
        "Data and Analytics",
        "Advertising and Marketing",
        "Media, PR, and Communications",
        "Arts",
        "Account Management"
    ],
    Consulting: [
        "Business Operations",
        "Data and Analytics",
        "Account Management",
        "Customer Service",
        "Product Management",
        "Project Management",
        "Administration and Office",
        "Advertising and Marketing"
    ],
    "Public Relations": [
        "Media, PR, and Communications",
        "Social Services",
        "Administration and Office",
        "Management",
        "Project Management",
        "Advertising and Marketing",
        "Writing and Editing",
        "Human Resources and Recruitment"
    ],
    Healthcare: [
        "Healthcare",
        "Science and Engineering",
        "Human Resources and Recruitment",
        "Customer Service",
        "Personal Care and Services",
        "Social Services",
        "Cleaning and Facilities",
        "Administration and Office"
    ],
    "HR & Recruiting": [
        "Human Resources and Recruitment",
        "Legal Services",
        "Data and Analytics",
        "Project Management",
        "Administration and Office",
        "Business Operations",
        "Writing and Editing",
        "Media, PR, and Communications"
    ],
    Government: [
        "Legal Services",
        "Social Services",
        "Project Management",
        "Management",
        "Administration and Office",
        "Transportation and Logistics",
        "Science and Engineering",
        "Accounting and Finance"
    ],
    "Data Science": [
        "Data and Analytics",
        "Software Engineering",
        "Product Management",
        "Project Management",
        "Computer and IT",
        "Science and Engineering",
        "Accounting and Finance",
        "Human Resources and Recruitment"
    ],
    "Product Management": [
        "Product Management",
        "Data and Analytics",
        "Design and UX",
        "Project Management",
        "Account Management",
        "Business Operations",
        "Management",
        "Advertising and Marketing"
    ],
    Sales: [
        "Sales",
        "Advertising and Marketing",
        "Customer Service",
        "Retail",
        "Real Estate",
        "Account Management",
        "Business Operations",
        "Product Management"
    ],
    "Administrative & Operations": [
        "Administration and Office",
        "Business Operations",
        "Account Management",
        "Project Management",
        "Data and Analytics",
        "Management",
        "Transportation and Logistics",
        "Computer and IT"
    ],
    "Project Management": [
        "Project Management",
        "Business Operations",
        "Administration and Office",
        "Product Management",
        "Data and Analytics",
        "Installation, Maintenance, and Repairs",
        "Construction",
        "Transportation and Logistics"
    ],
    "Account Management": [
        "Account Management",
        "Customer Service",
        "Business Operations",
        "Project Management",
        "Sales",
        "Product Management",
        "Data and Analytics",
        "Advertising and Marketing"
    ],
    "Manufacturing ": [
        "Manufacturing and Warehouse",
        "Transportation and Logistics",
        "Construction",
        "Project Management",
        "Cleaning and Facilities",
        "Installation, Maintenance, and Repairs",
        "Administration and Office",
        "Energy Generation and Mining"
    ],
    Insurance: [
        "Accounting and Finance",
        "Protective Services",
        "Administration and Office",
        "Project Management",
        "Sales",
        "Account Management",
        "Data and Analytics",
        "Media, PR, and Communications"
    ]
};

const jobCategoryItems = [
    {
        label: "Account Management",
        value: "account_management",
    },
    {
        label: "Accounting and Finance",
        value: "accounting_finance",
    },
    {
        label: "Administration and Office",
        value: "administration_office",
    },
    {
        label: "Advertising and Marketing",
        value: "advertising_marketing",
    },
    {
        label: "Animal Care",
        value: "animal_care",
    },
    {
        label: "Arts",
        value: "arts",
    },
    {
        label: "Business Operations",
        value: "business_operations",
    },
    {
        label: "Cleaning and Facilities",
        value: "cleaning_facilities",
    },
    {
        label: "Computer and IT",
        value: "computer_it",
    },
    {
        label: "Construction",
        value: "construction",
    },
    {
        label: "Customer Service",
        value: "customer_service",
    },
    {
        label: "Data and Analytics",
        value: "data_analytics",
    },
    {
        label: "Design and UX",
        value: "design_ux",
    },
    {
        label: "Education",
        value: "education",
    },
    {
        label: "Energy Generation and Mining",
        value: "energy_generation_mining",
    },
    {
        label: "Entertainment and Travel Services",
        value: "entertainment_travel",
    },
    {
        label: "Farming and Outdoors",
        value: "farming_outdoors",
    },
    {
        label: "Food and Hospitality Services",
        value: "food_hospitality",
    },
    {
        label: "Healthcare",
        value: "healthcare",
    },
    {
        label: "Human Resources and Recruitment",
        value: "human_resources_recruitment",
    },
    {
        label: "Installation, Maintenance, and Repairs",
        value: "installation_maintenance_repairs",
    },
    {
        label: "Legal Services",
        value: "legal",
    },
    {
        label: "Management",
        value: "management",
    },
    {
        label: "Manufacturing and Warehouse",
        value: "manufacturing_warehouse",
    },
    {
        label: "Media, PR, and Communications",
        value: "media_pr_communications",
    },
    {
        label: "Personal Care and Services",
        value: "personal_care",
    },
    {
        label: "Product Management",
        value: "product_management",
    },
    {
        label: "Project Management",
        value: "project_management",
    },
    {
        label: "Protective Services",
        value: "protective_services",
    },
    {
        label: "Real Estate",
        value: "real_estate",
    },
    {
        label: "Retail",
        value: "retail",
    },
    {
        label: "Sales",
        value: "sales",
    },
    {
        label: "Science and Engineering",
        value: "science_engineering",
    },
    {
        label: "Social Services",
        value: "social_services",
    },
    {
        label: "Software Engineering",
        value: "software_engineering",
    },
    {
        label: "Sports, Fitness, and Recreation",
        value: "sports_fitness_recreation",
    },
    {
        label: "Transportation and Logistics",
        value: "transportation_logistics",
    },
    {
        label: "Writing and Editing",
        value: "writing_editing",
    },
];

export {
    months,
    jobSearchQueryMaxlength,
    suggestionApiQueryMaxlength,
    ACTIVE_FEATURE_FLAGS,
    LEAD_MEDIA_SMALL_WIDTH,
    LEAD_MEDIA_MEDIUM_WIDTH,
    LEAD_MEDIA_LARGE_WIDTH,
    LEAD_MEDIA_ASPECT_HEIGHT,
    LEAD_MEDIA_ASPECT_WIDTH,
    LARGE_SCREEN_WIDTH_MIN,
    MEDIUM_SCREEN_WIDTH_MAX,
    MEDIUM_SCREEN_WIDTH_MIN,
    SMALL_SCREEN_WIDTH_MAX,
    SMALL_SCREEN_WIDTH_MIN,
    EXTRA_SMALL_SCREEN_WIDTH_MAX,
    FIXED_NAV_TABLET_HEIGHT,
    careerTypeMapping,
    jobCategoryItems
};
