import React from "react";

import { articleReducer, defaultArticleState } from "reducers/articleReducer";
import { userReducer } from "reducers/userReducer";
import { pageMetaDataReducer, defaultPageMetaDataState } from "reducers/pageMetaDataReducer";
import { useCombinedReducers } from "utils/reducers";
import {
    AppState,
    AppDispatch
} from "interfaces/context/appContextInterfaces";

const defaultAppState: AppState = {
    user: {
        isInitialized: false,
        logged_in: false,
        view_bookings: false,
        company: null,
        location: {
            city: "",
            region: "",
            country: "",
            dma: "",
            lat: null,
            lon: null,
            accuracy_radius: null,
        },
        cropped_name: "",
        email: "",
        favorite_posts: [],
        name: "",
        id: null
    },
    pageMetaData: defaultPageMetaDataState,
    article: defaultArticleState
};

const AppStateContext = React.createContext<AppState | undefined>(defaultAppState);
const AppDispatchContext = React.createContext<AppDispatch | undefined>(undefined);
/**
 * App Context Provider
 *
 * How to use:
 *
 * Wrap the provider around the components that need access to the context data.
 * ```
 * <AppProvider>
 *   <Component />
 * </AppProvider>
 * ```
 * Access the state in <Component />
 * ```
 * const state = useAppState();
 * ```
 * Call dispatch to update the context state
 * ```
 * const dispatch = useAppDispatch();
 * dispatch({ type: ACTION_TYPE, payload: ACTION_PAYLOAD });
 * ```
 */

const AppProvider: React.FC<React.PropsWithChildren> = (props: React.PropsWithChildren<AppState>) => {
    const { article, pageMetaData, children } = props;
    const [state, dispatch] = useCombinedReducers({
        article: React.useReducer(articleReducer, {
            ...defaultAppState.article,
            ...article
        }),
        pageMetaData: React.useReducer(pageMetaDataReducer, {
            ...defaultAppState.pageMetaData,
            ...pageMetaData
        }),
        user: React.useReducer(userReducer, {
            ...defaultAppState.user
        }),
    });

    return (
        <AppStateContext.Provider value={state as AppState}>
            <AppDispatchContext.Provider value={dispatch as AppDispatch}>
                {children}
            </AppDispatchContext.Provider>
        </AppStateContext.Provider>
    );
};

const useAppState = () => {
    const context = React.useContext(AppStateContext);
    if (context === undefined) {
        throw new Error("useAppState must be used within the AppProvider");
    }
    return context;
};

const useAppDispatch = () => {
    const context = React.useContext(AppDispatchContext);
    if (context === undefined) {
        throw new Error("useAppDispatch must be used within the AppProvider");
    }
    return context;
};

AppProvider.displayName = "AppProvider";
useAppState.displayName = "useAppState";
useAppDispatch.displayName = "useAppDispatch";

export { AppProvider, useAppState, useAppDispatch };
