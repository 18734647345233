import {
    PageMetaDataAction,
    PageMetaDataActionTypes,
    PageMetaData
} from "interfaces/context/appContextInterfaces";

export const defaultPageMetaDataState: PageMetaData = {
    pathName: "",
    referrer: "",
    search: "",
    href: ""
};

export const pageMetaDataReducer = (state: PageMetaData, action: PageMetaDataAction): PageMetaData => {
    switch (action.type) {
    case PageMetaDataActionTypes.updatePageMetaData: {
        const updatedState = {
            ...state,
            ...action.payload
        };
        return updatedState;
    }
    case PageMetaDataActionTypes.resetPageMetaData: {
        return defaultPageMetaDataState;
    }
    default: {
        return state;
    }
    }
};
